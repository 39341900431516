var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"delete_confirmation_modal","title":"Delete Member","size":"sm","no-close-on-backdrop":"","no-close-on-esc":"","hide-footer":""}},[_c('div',[_c('b-row',[_c('b-col',[_c('h1',[_vm._v("Are you sure?")]),_c('p',[_vm._v(" You are about to remove '"+_vm._s(_vm.delete_confirmation_modal.member.name)+"' from the organizing team. ")])])],1),_c('b-row',[_c('b-col',{staticClass:"text-right"},[_c('base-button',{staticClass:"my-4 btn-success",attrs:{"type":"danger","pill":true,"disabled":_vm.delete_confirmation_modal.loading,"loading":_vm.delete_confirmation_modal.loading,"success":_vm.delete_confirmation_modal.success},on:{"click":function($event){return _vm.delete_confirmation_modal_delete()}}},[_vm._v("Confirm ")]),_c('base-button',{staticClass:"my-4 btn-success",attrs:{"type":"success","pill":true,"disabled":_vm.delete_confirmation_modal.loading},on:{"click":function($event){return _vm.$bvModal.hide('delete_confirmation_modal')}}},[_vm._v("Close ")])],1)],1)],1)]),_c('b-modal',{attrs:{"id":"c_modal","title":"Invite Organizing Team Member","size":"md","no-close-on-backdrop":"","no-close-on-esc":"","hide-footer":""}},[_c('div',[_c('b-row',[_c('b-col',[_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.c_modal_invite_member)}}},[_c('input',{ref:"c_modal_form_submit_button",attrs:{"type":"submit","hidden":""}}),_c('b-row',[_c('b-col',[_c('base-input',{staticClass:"mb-3",attrs:{"pill":true,"label":"Email Address","name":"Email Address","rules":{
                                            required: true,
                                            email: true,
                                        },"placeholder":"Email of the judge to be invited","disabled":_vm.c_modal.loading},model:{value:(_vm.c_modal.form.email),callback:function ($$v) {_vm.$set(_vm.c_modal.form, "email", $$v)},expression:"c_modal.form.email"}})],1)],1)],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-right"},[_c('base-button',{staticClass:"my-4 btn-success",attrs:{"type":"danger","pill":true,"disabled":_vm.c_modal.loading},on:{"click":function($event){return _vm.$bvModal.hide('c_modal')}}},[_vm._v("Close ")]),_c('base-button',{staticClass:"my-4 btn-success",attrs:{"type":"success","pill":true,"disabled":_vm.c_modal.loading,"loading":_vm.c_modal.loading,"success":_vm.c_modal.success},on:{"click":function($event){return _vm.$refs.c_modal_form_submit_button.click()}}},[_vm._v("Invite ")])],1)],1)],1)]),_c('b-card',[_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('h1',[_vm._v("Hackathon Organizing Team")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[(_vm.is_hackathon_creator)?_c('el-tooltip',{attrs:{"content":"Add","placement":"top"}},[_c('base-button',{attrs:{"type":"primary","round":"","icon":"","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show('c_modal')}}},[_c('span',{staticClass:"btn-inner"},[_c('i',{staticClass:"fa fa-plus"})])])],1):_vm._e()],1)],1),(_vm.$apollo.loading && _vm.table_data.length == 0)?_c('div',[_c('b-row',{staticClass:"mt-2 d-flex align-items-center justify-content-center"},[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center"},[_c('Transition',[_c('h1',[_c('i',{staticClass:"fas fa-spinner fa-spin ml-1"})])])],1)],1)],1):_c('b-row',[_c('b-col',[_c('el-table',{staticClass:"table-responsive align-items-center table-flush no-lines",attrs:{"header-row-class-name":"thead-light","row-class-name":"table-rows","data":_vm.table_data,"empty-text":("No organizing team members yet" + (_vm.is_hackathon_creator
                                ? ', use the \'+\' button to invite members.'
                                : '...'))}},[_c('el-table-column',{attrs:{"label":"Name","min-width":"240px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [_c('a',{staticClass:"mb-0",attrs:{"href":("mailto:" + (row.user.email))}},[_vm._v(_vm._s(row.user.name))])]}}])}),_c('el-table-column',{attrs:{"label":"","min-width":"240px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [(row.linkedin_url)?_c('a',{attrs:{"href":row.linkedin_url,"target":"_blank"}},[_vm._v("Linked In")]):_vm._e()]}}])}),(_vm.is_hackathon_creator)?_c('el-table-column',{attrs:{"label":"Role","min-width":"240px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [(row.role == 'ADMIN')?_c('p',{staticClass:"mb-0"},[_vm._v(" Admin ")]):_vm._e(),(row.role == 'OWNER')?_c('p',{staticClass:"mb-0"},[_vm._v(" Owner ")]):_vm._e()]}}],null,false,1749511762)}):_vm._e(),(_vm.is_hackathon_creator)?_c('el-table-column',{attrs:{"min-width":"180px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return _c('div',{staticClass:"table-actions"},[_c('el-tooltip',{attrs:{"content":"Remove From Organizing Team","placement":"top"}},[_c('a',{staticClass:"table-action",attrs:{"href":"#!","data-toggle":"tooltip","data-original-title":"Delete Member"},on:{"click":function($event){$event.preventDefault();return _vm.delete_confirmation_modal_show(
                                                row
                                            )}}},[_c('i',{staticClass:"fas fa-trash"})])])],1)}}],null,false,853870074)}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }